<template>
  <button
    :aria-label="title"
    :title="title"
    :disabled="disabled"
    class="flex justify-center items-center w-8 h-8 bg-slate-900 rounded-full transition-all hover:bg-black animate-slide-down-fade-in07s focus:bg-black"
  >
    <span :class="iconClassComp"/>
  </button>
</template>

<script setup>
const props = defineProps({
  iconClass: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const iconClassComp = computed(() => `${props.iconClass} ${props.iconSize}`);
</script>
